import { Platform } from '@angular/cdk/platform';
import { HttpClient, provideHttpClient } from '@angular/common/http';
import { EnvironmentProviders, importProvidersFrom, LOCALE_ID, Provider } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { provideAnimations, provideNoopAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withRouterConfig } from '@angular/router';
import { CustomDateAdapter } from '@app/adapters/custom-date.adapter';
import { appRoutes } from '@app/app.routes';
import { ContentModule } from '@app/content/content.module';
import { PageModule } from '@app/page/page.module';
import { ZIndexModule } from '@app/z-index/z-index.module';
import { CoreModule } from '@core/core.module';
import { environment } from '@env/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateCompiler, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from '@shared/shared.module';
import { DropdownModule } from '@shared-modules/dropdown/dropdown.module';
import { TranslateSharedModule } from '@shared-modules/shared-translate.module';
import { MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

export const APP_FORMATS = {
  parse: { dateInput: 'LL' },
  display: { dateInput: 'DD/MM/YYYY', monthYearLabel: 'YYYY', dateA11yLabel: 'LL', monthYearA11yLabel: 'YYYY' },
};

export const appProviders: (Provider | EnvironmentProviders)[] = [
  // Date & locale
  { provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_FORMATS, Platform] },
  { provide: MAT_DATE_LOCALE, useValue: environment.defaultLanguage },
  { provide: MAT_DATE_FORMATS, useValue: APP_FORMATS },
  { provide: MESSAGE_FORMAT_CONFIG, useValue: { locales: environment.availableLanguages } },
  { provide: LOCALE_ID, useValue: environment.defaultLanguage },

  // Animations
  window.matchMedia('(prefers-reduced-motion)').matches ? provideNoopAnimations() : provideAnimations(),

  // Angular modules
  provideHttpClient(),

  // Our modules
  importProvidersFrom(CoreModule, ContentModule, DropdownModule, PageModule, SharedModule, ZIndexModule),

  // Routing
  provideRouter(appRoutes, withRouterConfig({ onSameUrlNavigation: 'reload', paramsInheritanceStrategy: 'always' })),

  // NGRX Store
  importProvidersFrom(
    StoreModule.forRoot({}),
    EffectsModule.forRoot(),
    environment.includeStoreDevTools
      ? StoreDevtoolsModule.instrument({ maxAge: 50, logOnly: environment.includeStoreDevTools, connectInZone: true })
      : [],
  ),

  // NGX Translate
  importProvidersFrom(
    TranslateSharedModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
      defaultLanguage: environment.fallbackLanguage,
      loader: {
        provide: TranslateLoader,
        // AoT requires an exported function for factories
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
        deps: [HttpClient],
      },
    }),
  ),
];
