import 'hammerjs';

import { registerLocaleData } from '@angular/common';
import localeDa from '@angular/common/locales/da';
import localeDaExtra from '@angular/common/locales/extra/da';
import localeSeExtra from '@angular/common/locales/extra/se';
import localeSe from '@angular/common/locales/se';
import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from '@app/app.component';
import { appProviders } from '@app/app.providers';
import { environment } from '@env/environment';

try {
  // Remove any existing service workers left over from when the users were using the legacy MatematikFessor.dk
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (const registration of registrations) {
      registration.unregister();
    }
  });
} catch (err) {}

if (environment.production) {
  enableProdMode();
}

registerLocaleData(localeDa, localeDaExtra);
registerLocaleData(localeSe, localeSeExtra);

bootstrapApplication(AppComponent, {
  providers: appProviders,
});
