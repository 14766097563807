<app-background-graphics-manager></app-background-graphics-manager>

<ng-container
  *ngIf="{
    combined: (combinedServerInteractionState$ | async)!,
    user: (userService.userServerInteractionState$ | async)!,
    isTeacher: (userService.isTeacher$ | async)!,
    loadSiteBootstrapServerInteractionState: (coreService.loadSiteBootstrapServerInteractionState$ | async)!,
    initialBootstrapCompleted: (coreService.initialBootstrapCompleted$ | async)!,
    azureAppConfiguration: (azureAppConfigurationService.azureAppConfigurationServerInteractionState$ | async)!,
    portalId: (coreService.portalId$ | async)!,
    breadcrumbs: (breadcrumbs$ | async) || [],
    previousBreadcrumb: (previousBreadcrumb$ | async),
    pageTitle: (pageTitle$ | async) || '',
    operationalNotification: (operationalNotificationService.operationalNotification$ | async) ?? undefined,
    operationalNotificationCanDismiss:
      (operationalNotificationService.canDismissOperationalNotification$ | async) ?? false
  } as sub"
>
  <app-loading-animation
    *ngIf="!sub.initialBootstrapCompleted && sub.combined.state === ServerInteractionStateType.LOADING; else appContent"
    [variant]="LoadingAnimationVariant.OVERLAY"
  >
  </app-loading-animation>

  <ng-template #appContent>
    <app-skip-to-content-button [contentId]="mainElementId"></app-skip-to-content-button>
    <app-header *ngIf="pageLayout === PageLayout.DEFAULT || pageLayout === PageLayout.PAGE"></app-header>
    <app-operational-notification
      *ngIf="sub.operationalNotification"
      [operationalNotification]="sub.operationalNotification"
      [dismissible]="sub.operationalNotificationCanDismiss"
      (dismiss)="
        operationalNotificationService.dismissOperationalNotification(sub.operationalNotification.notificationToken)
      "
    ></app-operational-notification>
    <app-breadcrumb *ngIf="sub.breadcrumbs.length > 0" [breadcrumbs]="sub.breadcrumbs"></app-breadcrumb>
    <nav
      *ngIf="
        (pageLayout === PageLayout.DEFAULT || pageLayout === PageLayout.FULLSCREEN) &&
        (backButtonRouterLink || exitButtonRouterLink)
      "
      class="relative-navigation-buttons"
    >
      <app-icon-button
        data-testid="relative-navigation-back-button"
        *ngIf="backButtonRouterLink"
        class="back-button"
        [icon]="Icons.CHEVRON_LEFT"
        [iconButtonRouterLink]="backButtonRouterLink"
        [title]="'app.actions.go-back' | translate"
      ></app-icon-button>
      <app-icon-button
        data-testid="relative-navigation-close-button"
        *ngIf="exitButtonRouterLink"
        class="exit-button"
        [icon]="Icons.CLOSE"
        [iconSize]="IconSize.LARGE"
        [circleFilled]="true"
        [iconButtonRouterLink]="exitButtonRouterLink"
        [title]="'app.actions.exit' | translate"
      ></app-icon-button>
    </nav>
    <main id="main" class="main-content">
      <app-server-error-message
        *ngIf="sub.azureAppConfiguration.state === ServerInteractionStateType.ERROR"
        [serverInteractionState]="sub.azureAppConfiguration"
        [heading]="'app.components.app.azure-app-configuration-load-error' | translate"
        [allowRetry]="true"
        (retry)="reloadAzureAppConfiguration()"
      >
      </app-server-error-message>
      <!-- app-server-error-message
        *ngIf="sub.user.state === ServerInteractionStateType.ERROR"
        [serverInteractionState]="sub.user"
        [heading]="'app.components.app.user-load-error' | translate"
        [allowRetry]="true"
        (retry)="reloadUserData()"
      >
      </app-server-error-message -->
      <app-server-error-message
        *ngIf="sub.loadSiteBootstrapServerInteractionState.state === ServerInteractionStateType.ERROR"
        [serverInteractionState]="sub.loadSiteBootstrapServerInteractionState"
        [heading]="'app.components.app.portal-configuration-load-error' | translate"
        [allowRetry]="true"
        (retry)="reloadPortalConfiguration()"
      ></app-server-error-message>
      <div
        *ngIf="sub.initialBootstrapCompleted"
        class="router-outlet-wrapper"
        [@route-animation]="getRouteAnimationIdentifier()"
      >
        <header>
          <app-page-title
            *ngIf="sub.pageTitle"
            [previousPageRouterLink]="sub.previousBreadcrumb?.url"
            [previousPageTitle]="sub.previousBreadcrumb?.label || ''"
            [heading]="sub.pageTitle"
          >
          </app-page-title>
          <div appTeleportOutlet="app-header"></div>
        </header>
        <router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>
      </div>
    </main>
    <app-footer
      [attr.hidden]="
        (pageLayout !== PageLayout.PAGE && !sub.isTeacher) || pageLayout === PageLayout.FULLSCREEN ? '' : null
      "
      class="screen"
    ></app-footer>
  </ng-template>
</ng-container>

<app-modal
  [isVisible]="pageService.displayLoginModal"
  [width]="'45rem'"
  (isVisibleChange)="onLoginModalVisibleChange($event)"
>
  <app-login-modal-content></app-login-modal-content>
</app-modal>

<app-modal
  [isVisible]="pageService.displayNotAuthorizedModal"
  [width]="'45rem'"
  (isVisibleChange)="onNotAuthorizedModalVisibleChange($event)"
>
  <app-not-authorized-modal-content
    (okClick)="onNotAuthorizedModalVisibleChange(false)"
  ></app-not-authorized-modal-content>
</app-modal>

<app-modal-outlet></app-modal-outlet>
<app-dropdown-outlet></app-dropdown-outlet>
<app-toast-message-list [style.padding-bottom]="toastBottomOffset + 'px'"></app-toast-message-list>
<app-tooltip-outlet></app-tooltip-outlet>
<div class="app-version" aria-hidden="true">{{ appVersion }}</div>
<app-scrollbar-ruler></app-scrollbar-ruler>
