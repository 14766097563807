import { Injectable } from '@angular/core';
import { mapToCanActivate, Router, Routes } from '@angular/router';
import { lastRoutes, routes } from '@app/app.routes';
import { AlineaUsersOnlyGuard } from '@app/guards/alinea-users-only.guard';
import { LoginGuard } from '@app/guards/login.guard';
import { StudentsOnlyGuard } from '@app/guards/students-only.guard';
import { TeachersOnlyGuard } from '@app/guards/teachers-only.guard';
import { SetPageForRoute } from '@app/page/guards/set-page-for-route.guard';
import { AzureAppConfigurationService } from '@core/services/azure-app-configuration.service';
import { CoreService } from '@core/services/core.service';
import { PageLayout } from '@shared-enums/page-layout.enum';
import { getObservableValueSync } from '@utils/observables';

import { CustomPageInfo } from '../interfaces/project/portal-configuration.interface';

@Injectable({
  providedIn: 'root',
})
export class RoutingBuilderService {
  constructor(
    private router: Router,
    private coreService: CoreService,
    private azureAppConfigurationService: AzureAppConfigurationService,
  ) {}

  public CreateRouting(customPageInfoArray: CustomPageInfo[]): void {
    const newRoutes: Routes = [...routes];
    customPageInfoArray.forEach((customPageInfo) => {
      const customUrl: string = customPageInfo.customUrl ?? ' ';

      switch (customPageInfo.pageType) {
        case 'lounge-team-admin':
          newRoutes.push({
            path: customUrl,
            loadChildren: () => import('@app/teams-admin/teams-admin.module').then((m) => m.TeamsAdminModule),
            canActivate: mapToCanActivate([LoginGuard, TeachersOnlyGuard, SetPageForRoute]),
            data: {
              pageId: customPageInfo.id,
              pageType: customPageInfo.pageType,
              customUrl,
              customRoute: true,
            },
          });
          break;

        case 'mf20-achievements-page':
          newRoutes.push({
            path: customUrl,
            loadChildren: () =>
              import('@app/achievements-page/achievements-page.module').then((m) => m.AchievementsPageModule),
            canActivate: mapToCanActivate([LoginGuard, SetPageForRoute]),
            data: {
              pageId: customPageInfo.id,
              pageType: customPageInfo.pageType,
              customUrl,
              customRoute: true,
              background: {
                showPattern: true,
                imageUrl: '/assets/svg/backgrounds/achievements-page/badge-flash-background-1920-1080.svg',
              },
            },
          });
          break;

        case 'mf20-dashboard-teacher':
          newRoutes.push({
            path: customUrl,
            loadChildren: () =>
              import('@app/teacher-dashboard/teacher-dashboard.module').then((m) => m.TeacherDashboardModule),
            canActivate: mapToCanActivate([LoginGuard, TeachersOnlyGuard, SetPageForRoute]),
            data: {
              pageId: customPageInfo.id,
              pageType: customPageInfo.pageType,
              customUrl,
              customRoute: true,
            },
          });
          break;

        case 'mf20-dashboard-student':
          newRoutes.push({
            path: customUrl,
            loadChildren: () =>
              import('@app/student-dashboard/student-dashboard.module').then((m) => m.StudentDashboardModule),
            canActivate: mapToCanActivate([LoginGuard, StudentsOnlyGuard, SetPageForRoute]),
            data: {
              pageId: customPageInfo.id,
              pageType: customPageInfo.pageType,
              customUrl,
              customRoute: true,
            },
          });
          break;
        // todo: is this (mf20-insight) used any more?
        case 'mf20-insight':
          newRoutes.push({
            path: customUrl,
            loadChildren: () =>
              import('@app/statistics-teacher/statistics-teacher.module').then(
                (module) => module.StatisticsTeacherModule,
              ),
            canActivate: mapToCanActivate([LoginGuard, TeachersOnlyGuard, AlineaUsersOnlyGuard, SetPageForRoute]),
            data: {
              pageId: customPageInfo.id,
              pageType: customPageInfo.pageType,
              customUrl,
              customRoute: true,
            },
          });
          break;
        case 'mf20-evaluation':
          newRoutes.push({
            path: customUrl,
            loadChildren: () =>
              this.azureAppConfigurationService.getFeatureFlag('evaluationV2')
                ? import('@app/features/evaluation/evaluation.routes').then((r) => r.evaluationRoutes)
                : import('@app/statistics-evaluation/statistics-evaluation.module').then(
                    (module) => module.StatisticsEvaluationModule,
                  ),
            canActivate: mapToCanActivate([LoginGuard, TeachersOnlyGuard, SetPageForRoute]),
            data: {
              pageId: customPageInfo.id,
              pageType: customPageInfo.pageType,
              customUrl,
              customRoute: true,
            },
          });
          break;
        case 'mf20-assignments':
          newRoutes.push({
            path: customUrl,
            loadChildren: () =>
              import('@app/assignments-assigned/assignments-assigned.module').then(
                (module) => module.AssignmentsAssignedModule,
              ),
            canActivate: mapToCanActivate([LoginGuard, SetPageForRoute]),
            data: {
              pageId: customPageInfo.id,
              pageType: customPageInfo.pageType,
              customUrl,
              customRoute: true,
              background: { color: 'rgb(235, 242, 253)', showGraphics: false },
            },
          });
          break;
        case 'mf20-assignments-managed':
          newRoutes.push({
            path: customUrl,
            loadChildren: () =>
              import('@app/assignments-managed/assignments-managed.module').then(
                (module) => module.AssignmentsManagedModule,
              ),
            canActivate: mapToCanActivate([LoginGuard, TeachersOnlyGuard, SetPageForRoute]),
            data: {
              pageId: customPageInfo.id,
              pageType: customPageInfo.pageType,
              customUrl,
              customRoute: true,
            },
          });
          break;
        case 'mf20-find-content':
          newRoutes.push({
            path: customUrl,
            loadChildren: () =>
              import('@app/find-content/find-content.module').then((module) => module.FindContentModule),
            canActivate: mapToCanActivate([LoginGuard, TeachersOnlyGuard, SetPageForRoute]),
            data: {
              pageId: customPageInfo.id,
              pageType: customPageInfo.pageType,
              customUrl,
              customRoute: true,
            },
          });
          break;
        case 'mf20-training':
          newRoutes.push({
            path: customUrl,
            loadChildren: () => import('@app/training/training.module').then((module) => module.TrainingModule),
            canActivate: mapToCanActivate([LoginGuard, SetPageForRoute]),
            data: {
              pageId: customPageInfo.id,
              pageType: customPageInfo.pageType,
              customUrl,
              customRoute: true,
            },
          });
          break;
        case 'frontpage':
        case 'contentpage':
          if (!customPageInfo.customUrl) {
            if (customPageInfo.pageType === 'contentpage') {
              this.NotInsertedIntoRouting(customPageInfo);
              break;
            }
            customPageInfo = { ...customPageInfo, customUrl: ' ' };
          }
          newRoutes.push({
            path: customUrl,
            loadComponent: () => import('@app/page/page.component').then((x) => x.PageComponent),
            canActivate: mapToCanActivate([SetPageForRoute]),
            data: {
              layout: { pageLayout: PageLayout.PAGE },
              pageId: customPageInfo.id,
              pageType: customPageInfo.pageType,
              customUrl,
              customRoute: true,
            },
          });
          break;

        default:
          this.NotInsertedIntoRouting(customPageInfo);
          break;
      }
    });

    lastRoutes.forEach((lastRoute) => newRoutes.push(lastRoute));

    if (this.coreService.isDevServer) {
      console.log('newRoutes:', newRoutes);
    }

    this.router.resetConfig(newRoutes);
    this.router.navigateByUrl(this.router.url);
  }

  private NotInsertedIntoRouting(cu: CustomPageInfo): void {
    const umbracoContentEditUrl = 'https://lri-mitalineaumb-dev.azurewebsites.net/umbraco#/content/content/edit/';
    if (this.coreService.isDevServer) {
      console.log('Not inserted into routing:', cu);
      console.log('EDIT in Umbraco : ' + umbracoContentEditUrl + cu.id);
    }
  }
}
