import { Injectable } from '@angular/core';
import { HtmlService } from '@shared-services/html.service';

import { MegaMenu, MegaMenuTab } from '../interfaces/mega-menu.interface';
import { UmbNavbar } from '../interfaces/umbraco/umb-navbar.interface';

@Injectable({
  providedIn: 'root',
})
export class MegaMenuMapper {
  constructor(private htmlService: HtmlService) {}

  mapMegaMenuFromApi(navbar: UmbNavbar): MegaMenu {
    const result: MegaMenu = {
      tabs: [],
    };

    const css: string[] = [];

    navbar.rows.forEach((row, i) => {
      row.columns.forEach((column, j) => {
        if (column.value?.items) {
          column.value?.items.forEach((item, k) => {
            if (item.type === 'dropdown') {
              const dropdown: MegaMenuTab = {
                title: item.name,
                label: item.label,
                items: [],
              };
              item.items.forEach((child, l) => {
                const className = `hover-${i}-${j}-${k}-${l}`;
                if (child.hoverColor) {
                  css.push(
                    `@media (hover: hover) { app-mega-menu .${className}:hover { background-color: ${child.hoverColor} !important; } }`,
                  );
                }

                dropdown.items.push({
                  icon: child.iconUrl,
                  title: child.name,
                  label: child.label,
                  url: child.url ? `${child.url}?portalclick=megamenu` : '',
                  description: this.htmlService.fixHtml(child.description),
                  hover: child.hoverColor
                    ? {
                        className: className,
                        color: child.hoverColor,
                      }
                    : undefined,
                });
              });
              result.tabs.push(dropdown);
            } else if (item.type === 'link-external') {
              result.link = {
                title: item.name,
                url: item.url ? `${item.url}?portalclick=megamenu` : '',
              };
            }
          });
        }
      });
    });
    if (result) {
      if (css) {
        const style = document.createElement('style');
        style.appendChild(document.createTextNode(css.join('')));
        document.getElementsByTagName('head')[0].appendChild(style);
      }
      result.currentTab = result.tabs[0];
    }
    return result;
  }
}
