import { Injectable } from '@angular/core';
import { AzureAppConfigurationService } from '@app/core/services/azure-app-configuration.service';
import { UserService } from '@app/user/services/user.service';
import { Icons } from '@shared-data/icons';
import { Icon } from '@shared-interfaces/icon.interface';
import { HtmlService } from '@shared-services/html.service';
import { getCustomUrl } from '@utils/umbraco';

import { NavbarItem } from '../interfaces/navbar.interface';
import { UmbNavbar } from '../interfaces/umbraco/umb-navbar.interface';
import { UmbNavbarItem } from '../interfaces/umbraco/umb-navbar-item.interface';
import { linkType } from '../page.types';
import { NavbarService } from '../services/navbar.service';

@Injectable({
  providedIn: 'root',
})
export class NavbarMapper {
  constructor(
    private navbarService: NavbarService,
    private htmlService: HtmlService,
    private userService: UserService,
    private azureAppConfigService: AzureAppConfigurationService,
  ) {}

  private pushItemToArray(item: UmbNavbarItem, items: NavbarItem[], array: NavbarItem[], dropdownIndex?: number): void {
    if (!item.isActive) {
      // don't add items that are not active
      return;
    }

    const url = getCustomUrl(item.customUrl);
    let icon: Icon | undefined;

    switch (item.pageType) {
      case 'mf20-find-content':
        icon = Icons.SEARCH;
        this.navbarService.setFindContent({ id: item.id, url, pageType: item.pageType });
        break;
      case 'mf20-evaluation':
        icon = Icons.INFO;
        this.navbarService.setEvaluation({ id: item.id, url });
        break;
      case 'mf20-assignments':
        icon = Icons.FAVORITE_OFF;
        this.navbarService.setAssignmentsAssigned({ id: item.id, url });
        break;
      case 'mf20-assignments-managed':
        icon = Icons.FAVORITE_OFF;
        this.navbarService.setAssignmentsManaged({ id: item.id, url });
        break;
      case 'mf20-insight':
        if (!this.userService.isAlineaUser) {
          return;
        }
        icon = Icons.TRAINING_COUNT;
        this.navbarService.setInsight({ id: item.id, url });
        break;
      case 'mf20-training':
        icon = Icons.FAVORITE_OFF;
        this.navbarService.setTraining({ id: item.id, url });
        break;
      case 'lounge-team-admin':
        icon = Icons.FAVORITE_OFF;
        this.navbarService.setTeamsAdmin({ id: item.id, url });
        break;
    }

    if (item.isHidden) {
      return;
    }

    if (item.type !== 'link-external') {
      if (item.contentTypeAlias === 'frontpage') {
        item.url = '/';
      } else if (item.customUrl) {
        item.url = url;
      }
    }

    array.push({
      id: item.type === 'dropdown' && dropdownIndex !== null ? dropdownIndex : item.id,
      items: items,
      title: item.name,
      type: this.getLinkType(item),
      target: item.target,
      url: item.url,
      icon,
      tooltip: item.label,
      embed: this.isEmbedVideo(item) ? this.htmlService.safeHtml(item.description!) : item.description,
      pageType: item.type === 'link-content' ? item.pageType || 'contentpage' : undefined,
    });
  }

  /// TODO: solution while we wait for TOAST board task: https://alinea.atlassian.net/browse/TOAST-1072
  /// MF2-3704 embed video topbar menu items
  private getLinkType(item: UmbNavbarItem): linkType {
    if (this.isEmbedVideo(item)) {
      return 'embed-video';
    }
    return item.type;
  }

  private isEmbedVideo(item: UmbNavbarItem): boolean {
    return item.type === 'link-external' && item.url === 'youtube' && !!item.label && !!item.description;
  }

  public mapNavbarFromApi(navbar: UmbNavbar): NavbarItem[] {
    const result: NavbarItem[] = [];

    for (const row of navbar.rows) {
      for (const column of row.columns) {
        if (column.value) {
          const items: UmbNavbarItem[] = [];

          column.value.items.forEach((item) => {
            items.push(item);
          });
          items.forEach((item, index) => {
            const navbarItems: NavbarItem[] = [];

            if (item.type === 'dropdown') {
              for (const child of item.items) {
                this.pushItemToArray(child, [], navbarItems);
              }
            }

            this.pushItemToArray(item, navbarItems, result, index);
          });
        }
      }
    }
    return result;
  }

  public isLinkActive(item: NavbarItem, currentUrl: string): boolean {
    const a = item.url ? item.url.split('/') : [];
    const b = currentUrl ? currentUrl.split('/') : [];
    if (a?.length > 1 && b.length > 1) {
      return a[1] === b[1];
    }
    return item.url ? currentUrl.indexOf(item.url) === 0 : false;
  }

  public isDropdownActive(items: NavbarItem[]): boolean {
    return items.map((elem) => elem.url).find((elem) => elem === window.location.pathname) ? true : false;
  }
}
